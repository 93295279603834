/* Style for the modal */
.modal-your-booking {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
}

/* Modal content */
.modal-content-your-booking {
  /* background-color: #ead8b1 !important; */
  background-image: url("../assets/background1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 1em;
  margin: 50% auto; /* Center vertically */
  padding: 20px;
  border: 1px solid #888;
  width: 300px !important;
  height: 50vh;
  border-radius: 10px;
  text-align: center;
  overflow-y: scroll;
  display: grid;
}

#user-bookings {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 5px;
  row-gap: 10px;
  color: #ffc55a;
}

#user-bookings p:first-child {
  text-align: start;
}

#user-bookings p:last-child {
  text-align: end;
}

/* Close button */
.close-your-booking {
  position: relative;
  color: #ffc55a !important;
  justify-self: end !important;
  font-size: 24px;
  font-weight: bold;
}

.close-your-booking:hover,
.close-your-booking:focus {
  color: white !important;
  cursor: pointer;
}

/* Style the buttons */
button {
  margin: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: #ffc55a;
  border: 4px outset #ffc55a;
  border-radius: 10px !important;
  cursor: pointer;
}

button:hover {
  border-style: inset;
}

/* Medium devices (tablets, 600px to 1200px) */
@media (min-width: 601px) and (max-width: 1200px) {
  /* Modal content */
  .modal-content-your-booking {
    margin-top: 15%;
    width: 400px !important; /* Width of the dialog */
  }
}
/* Large devices (desktops, 1200px and up) */
@media (min-width: 1201px) {
  /* Modal content */
  .modal-content-your-booking {
    margin-top: 15%;
    width: 500px !important; /* Width of the dialog */
  }
}

.modal-content-your-booking::-webkit-scrollbar {
  width: 10px; /* Width for vertical scrollbar */
  height: 10px; /* Height for horizontal scrollbar */
}

/* Track (background) */
.modal-content-your-booking::-webkit-scrollbar-track {
  background: white;
  /* background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d); */
  border-radius: 5px;
}

/* Thumb (scroll handle) */
.modal-content-your-booking::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d);
  /* background: white; */
  border-radius: 5px;
}

/* Hover effect on the thumb */
.modal-content-your-booking::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to top, #8c52ff, #ff914d, #ff914d);
}
