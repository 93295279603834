/* Style for the modal */
.modal_booking_picker {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 10; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
}

/* Modal content */
.modal_content_booking_picker {
  /* background-color: #ead8b1 !important; */
  background-image: url("../assets/background1.png");
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  background-size: cover;
  margin: 50% auto; /* Center vertically */
  padding: 20px;
  border: 1px solid #888;
  width: 300px !important; /* Width of the dialog */
  border-radius: 10px;
  text-align: center;
  min-height: fit-content !important;
  row-gap: 20px;
  /* align-self: center; */
}

.modal_content_booking_picker > h2,
.modal_content_booking_picker > p {
  color: #ffc55a;
}
/* Close button */
.closeclose_booking_picker {
  position: relative;
  color: #ffc55a !important;
  align-self: flex-end;
  font-size: 24px;
  font-weight: bold;
}

.closeclose_booking_picker:hover,
.closeclose_booking_picker:focus {
  color: #000;
  cursor: pointer;
}

/* Style the buttons */
button {
  margin: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: #ffc55a;
  border: 4px outset #ffc55a;
  border-radius: 10px !important;
  cursor: pointer;
}

button:hover {
  border-style: inset;
}

.MuiInputBase-root > input {
  color: #ffc55a;
}
.MuiTextField-root > label {
  color: #ffc55a;
}
.MuiButtonBase-root > svg {
  color: #ffc55a;
}
.MuiOutlinedInput-notchedOutline {
  border: 2px solid #ffc55a !important;
}



/* Medium devices (tablets, 600px to 1200px) */
@media (min-width: 601px) and (max-width: 1200px) {
  /* Modal content */
  .modal_content_booking_picker {
    margin-top: 15%;
    width: 400px !important; /* Width of the dialog */
  }
}
/* Large devices (desktops, 1200px and up) */
@media (min-width: 1201px) {
  /* Modal content */
  .modal_content_booking_picker {
    margin-top: 15%;
    width: 500px !important; /* Width of the dialog */
  }
}
