#loader {
  width: 100%;
  height: 100%;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
#loader > img {
  z-index: 2;
  width: 9rem;
  height: 3rem;
  border-radius: 100%;
  border: 2px outset #ffc55a;
  animation: loader 1s infinite;
}

@keyframes loader {
  from { 
    opacity: 0;
    transform: rotate(0deg) scale(0);
  }
  to {
    opacity: 1;
    transform: rotate(360deg) scale(1);
  }
}
