.androidliButton {

    display: inline;
    margin-left: 10px;
}
.androidliAnchor{

    text-decoration: none; 
    color: white ;
    animation: androidliAchorAnimation infinite alternate-reverse 1s;
}
@keyframes androidliAchorAnimation {

    from{

        font-size: 5px;
        color: #ffc55a;
    }to {

        color:white
    }
    
}
@media (min-width: 1025px) {
    .androidliButton{
        display: none;
    }
}