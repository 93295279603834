#service_cart {
  width: 6em;
  height: max-content;
  background-color: #ead8b1;
  border-radius: 10px 0 10px 0;
  position: relative;
  box-shadow: 0px 3px 5px -1px #ffc55a;
}
#service_cart_header {
  background-image: url("../assets/HairCutting.jpg");
  background-position: center;
  background-size: contain;
  border-radius: 10px 0 10px 0;
  width: 100%;
  height: 80px;
  align-self: end;
}
#service_cart_footer {
  margin-top: 10px;
  text-align: center;
  font-size: 0.5em;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content;
  
}
#service_cart_footer > div {

  padding-left: 5px;
  padding-right: 5px;
}
#service_cart > i {
  display: none;
  position: absolute;
  top: -20px;
  right: 3px;
  color: white;
  font-size: 1em;
  animation: double_check infinite 1s alternate-reverse;
  background-color: #ffc55a;
  padding: 5px;
  border-radius: 50%;
}
#service_cart_footer > button {
  padding: 2px 5px;
  color: #001f3f;
  
}
@keyframes double_check {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

/* Here I am adding custom view */
@media (min-width: 550px) and (max-width: 720px) {
  #service_cart {
    width: 9em;
    height: 13em;
  }
  #service_cart_header {
    width: 100%;
    height: 60%;
  }
  #service_cart_footer {
    font-size: 0.6em;
  }
}
@media (min-width: 721px) and (max-width: 1024px) {
  #service_cart {
    width: 12em;
    height: 18em;
  }
  #service_cart_header {
    width: 100%;
    height: 60%;
  }
  #service_cart_footer {
    font-size: 0.8em;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr;
  }
}
@media (min-width: 1025px) {
  #service_cart {
    width: 12em;
    height: 18em;
  }
  #service_cart_header {
    width: 100%;
    height: 60%;
  }
  #service_cart_footer {
    font-size: 0.8em;
    font-weight: 500;
    display: grid;
    grid-template-columns: 1fr;
  }
}
