#about {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#about_img {
  background-image: url("../assets/C-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
  width: 90vw;
  max-height: 50vh;
  height: 50vw;
  border: 5px #ffc55a groove;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  /* animation: img_slider 1s forwards; */
}

#about_content {
  /* width: 0;
  height: 0; */
  padding: 10px;
  background-color: #ffc55a;
  border-left: 5px #ffc55a groove;
  border-right: 5px #ffc55a groove;
  border-bottom: 5px #ffc55a groove;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #001f3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  word-break: break-all;
  animation: content_slider 1s forwards;
}
#card_grid h6 {
  text-decoration: underline;
  text-underline-offset: 5px;
}

@keyframes content_slider {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 80vw;
    height: auto;
  }
}
/* @keyframes img_slider {
    from{
        transform: scale(0)
    }to {
        transform: scale(1)
    }
} */

/* Small Laptop (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for small laptop screens */
  #card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 100px 100px 100px;
    row-gap: 10px;
    column-gap: 20px;
  }
  #card_grid div {
    border: 5px #ffc55a groove;
    border-style: groove;
    padding: 20px 10px;
    background-color: #ead8b1;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  #card_grid div:nth-child(1) {
    grid-row: span 4;
  }
  #card_grid div:nth-child(2) {
    grid-row: span 4;
  }
  #about_content {
    padding: 30px;
  }
}

/* Large devices (desktops, 1025px and up) */
@media (min-width: 1025px) {
  #card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 100px 100px 100px;
    row-gap: 10px;
    column-gap: 20px;
  }
  #card_grid div {
    border: 5px #ffc55a groove;
    border-style: groove;
    padding: 20px 10px;
    background-color: #ead8b1;
    border-top-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
  #card_grid div:nth-child(1) {
    grid-row: span 4;
  }
  #card_grid div:nth-child(2) {
    grid-row: span 4;
  }
  #card_grid div:nth-child(3) {
    grid-row: span 4;
  }
  #card_grid div:nth-child(4) {
    grid-row: span 4;
  }
  #about_content {
    padding: 30px;
  }
}
