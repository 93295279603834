#service {
  display: flex;
  flex-direction: column;
  margin: 1.3em;
}
#service > span {
  font-size: 1.5em;
  font-weight: 500;
  color: #ffc55a;
  text-align: center;
  text-decoration: underline;
  text-underline-offset: 0.3em;
  animation: service_category 1s infinite alternate-reverse;
}
#service_container {
  margin: 20px 0 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  column-gap: 2em;
  row-gap: 2em;
  flex-wrap: wrap;
}

@keyframes service_category {
  from {
    color: #ffc55a;
  }
  to {
    color: #ead8b1;
  }
}

/* Custom scroll bar design */

body::-webkit-scrollbar {
  width: 10px; /* Width for vertical scrollbar */
  height: 10px; /* Height for horizontal scrollbar */
}

/* Track (background) */
body::-webkit-scrollbar-track {
  background: white;
  /* background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d); */
  border-radius: 5px;
}

/* Thumb (scroll handle) */
body::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d);
  /* background: white; */
  border-radius: 5px;
}

/* Hover effect on the thumb */
body::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to top, #8c52ff, #ff914d, #ff914d);
}

@media (min-width: 721px) and (max-width: 1024px) {
  #service_container {
    margin: 40px 0 30px 0;
    display: grid;
    grid-template-columns: max-content max-content max-content;
    column-gap: 3.5em;
    row-gap: 3em;
  }
}

@media (min-width: 1025px) {
  #service_container {
    margin: 80px 0 30px 0;
    display: grid;
    grid-template-columns: max-content max-content max-content max-content;
    column-gap: 3.5em;
    row-gap: 3em;
  }
}
