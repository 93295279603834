.liButton {

    display: inline;
    margin-left: 10px;
}
.liAnchor{

    text-decoration: none; 
    color: white ;
    animation: liAchorAnimation infinite alternate-reverse 1s;
}
@keyframes liAchorAnimation {

    from{

        color: #ffc55a;
    }to {

        color:white
    }
    
}
@media (min-width: 1025px) {
    .liButton{
        display: none;
    }
}