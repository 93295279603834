#divider {
  height: 3px;
  border-radius: 5px;
  background-image: linear-gradient(to right, #8c52ff, #ff914d, #ff914d);
}
.horizontal_divider {
  width: 15em;
  margin: auto;
  margin-bottom: 18px;
}
