/* Style for the modal */
.modal_cart {
  /* Hidden by default */
  display: none;
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  justify-content: center !important;
  align-items: baseline;
}

/* Modal content */
.modal-content_cart {
  /* background-color: #ead8b1 !important; */
  background-image: url("../assets/background1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  border: 1px solid #888;
  width: 300px !important; /* Width of the dialog */
  border-radius: 10px 10px 0 0;
  text-align: center;
  position: absolute;
  bottom: 55px;
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: scroll;
}

#cart_details > ul {
  color: #ffc55a;
  text-align: left;
}
/* #cart_details > div > p:first-child {
  text-align: left;
}
#cart_details > div > p:last-child {
  text-align: right;
} */
/* Close button */
.close_cart {
  position: relative;
  color: #ffc55a !important;
  align-self: flex-end;
  font-size: 24px;
  font-weight: bold;
}

.close_cart:hover,
.close_cart:focus {
  color: #000;
  cursor: pointer;
}

/* Style the buttons */
button {
  margin: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: #ffc55a;
  border: 4px outset #ffc55a;
  border-radius: 10px !important;
  cursor: pointer;
}

button:hover {
  border-style: inset;
}

/* #cart_details > div,
#amount {
  display: grid;
  grid-template-columns: 70% 30%;
  grid-template-rows: auto;
} */

#cart_details {
  display: flex;
  flex-direction: column;
  margin: 15px 0 20px 8px;
}

#amount > h6 {
  background: linear-gradient(to right, #8c52ff, #ff914d, #ff914d);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
#amount > h6:first-child {
  text-align: left;
}
#amount > h6:last-child {
  text-align: right;
}

#cancelBtn_cart {
  margin-bottom: 30px;
}

/* Custom scroll bar design */

.modal-content_cart::-webkit-scrollbar {
  width: 10px; /* Width for vertical scrollbar */
  height: 10px; /* Height for horizontal scrollbar */
}

/* Track (background) */
.modal-content_cart::-webkit-scrollbar-track {
  background: white;
  /* background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d); */
  border-radius: 5px;
}

/* Thumb (scroll handle) */
.modal-content_cart::-webkit-scrollbar-thumb {
  background-image: linear-gradient(to bottom, #8c52ff, #ff914d, #ff914d);
  /* background: white; */
  border-radius: 5px;
}

/* Hover effect on the thumb */
.modal-content_cart::-webkit-scrollbar-thumb:hover {
  background-image: linear-gradient(to top, #8c52ff, #ff914d, #ff914d);
}

/* Medium devices (tablets, 600px to 1200px) */
@media (min-width: 601px) and (max-width: 1200px) {
  /* Modal content */
  .modal-content_cart {
    margin-top: 15%;
    width: 400px !important; /* Width of the dialog */
  }
}
/* Large devices (desktops, 1200px and up) */
@media (min-width: 1201px) {
  /* Modal content */
  .modal-content_cart {
    margin-top: 15%;
    width: 500px !important; /* Width of the dialog */
  }
}
