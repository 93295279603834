#contact {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#contact_img {
  background-image: url("../assets/C-2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
  width: 90vw;
  max-height: 50vh;
  height: 50vw;
  border: 5px #ffc55a groove;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

#contact_content {
  width: 80vw;
  height: auto;
  padding: 10px;
  background-color: #ffc55a;
  border-left: 5px #ffc55a groove;
  border-right: 5px #ffc55a groove;
  border-bottom: 5px #ffc55a groove;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #001f3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  word-break: break-all;
  animation: content_slider 1s forwards;
}

#contact_content a {
  text-decoration: none;
  color: #001f3f;
  cursor: pointer;
}

@keyframes content_slider {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 80vw;
    height: auto;
  }
}
