.modal_slider {
  /* Hidden by default */
  display: none;
  /* display: inline; */
  position: fixed; /* Stay in place */
  z-index: 2; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
}

/* Modal content */
.modal-content_slider {
  /* background-image: url("../assets/background1.png"); */
  background-image: linear-gradient(to top, #8c52ff, #ff914d, #000080);
  position: absolute;
  display: flex;
  z-index: 10;
  flex-direction: column;
  background-color: #ead8b1 !important;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /* margin: 50% auto; Center vertically */
  padding: 20px;
  margin: 0;
  right: 0px;
  /* border: 1px solid #888; */
  border-top: 2px inset #ffc55a;
  border-bottom: 2px inset #ffc55a;
  border-left: 2px inset #ffc55a;
  width: 250px !important; /* Width of the dialog */
  height: 100vh;
  border-radius: 10px 0px 0px 150px;
  text-align: center;
}

.modal-content_slider > h3,
.modal-content_slider > p {
  color: #ffc55a;
}
/* Close button */
.close_slider {
  position: relative;
  color: #ffc55a !important;
  align-self: flex-end;
  font-size: 24px;
  font-weight: bold;
}

.close_slider:hover,
.close_slider:focus {
  color: #000;
  cursor: pointer;
}

/* Style the buttons */
button {
  margin: 10px;
  padding: 10px 20px;
  background-color: transparent;
  color: #ffc55a;
  border: 2px outset #ffc55a;
  border-radius: 10px !important;
  cursor: pointer;
}

button:hover {
  border-style: inset;
}
