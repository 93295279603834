#services {
  width: auto;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
#services_img {
  background-image: url("../assets/C-5.jpg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 20px;
  width: 90vw;
  max-height: 50vh;
  height: 50vw;
  border: 5px #ffc55a groove;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
#services_content {
  width: 0;
  height: 0;
  padding: 10px;
  background-color: #ffc55a;
  border-left: 5px #ffc55a groove;
  border-right: 5px #ffc55a groove;
  border-bottom: 5px #ffc55a groove;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: #001f3f;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: justify;
  word-break: break-all;
  animation: content_slider 1s forwards;
}

@keyframes content_slider {
  from {
    width: 0;
    height: 0;
  }
  to {
    width: 80vw;
    height: auto;
  }
}
/* Small Laptop (769px to 1024px) */
@media (min-width: 769px) and (max-width: 1024px) {
  /* Styles for small laptop screens */
  #services_img {
    width: 80vw;
    max-height: 60vw;
    height: 40vw;
  }
  @keyframes content_slider {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 70vw;
      height: auto;
    }
  }
}
/* Large devices (desktops, 1025px and up) */
@media (min-width: 1025px) {
  #services_img {
    width: 80vw;
    max-height: 60vw;
    height: 35vw;
  }
  @keyframes content_slider {
    from {
      width: 0;
      height: 0;
    }
    to {
      width: 70vw;
      height: auto;
    }
  }
}
